body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.highcharts-credits {
  display: none;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 1000px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #edeeef;
  border-radius: 1000px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #94a3b8;
  border-radius: 1000px;
}

a {
  text-decoration: none;
}
